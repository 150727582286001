/*
 * @Author: zhengda.lv
 * @Date: 2022-01-11 16:32:17
 * @LastEditTime: 2022-04-06 11:28:05
 * @LastEditors: zhengda.lv
 * @Description:
 */
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { UndefinedString } from '@/views/LocalRegistrationReport/types';
import { findBrandByBu } from '@/API/salesReporting';
import { searchVehicle } from '@/API/checking/checkingManagement';
import {
    getRegionList as getRegionListAPI,
    getTypeClass,
} from '@/API/salesReporting/claimCheckingReport';
import { getAreasDate } from '@/API/checking/claimDelayRuleSetup';
import { message } from 'ant-design-vue';

const useSearchCondition = () => {
    // 页面头部内容
    const spaceSize = 6;
    const viewTypes = [
        {
            title: 'By Dealer View',
            id: 1,
        },
        {
            title: 'By Other View',
            
            id: 2,
        },
    ];
    const selectedViewType = ref(1);
    const selectViewType = (e: Event) => {
        selectedViewType.value = parseInt((e.target as HTMLInputElement).value);
    };

    // bu相关 //TODO 传给后端 id 还是 name
    const { state } = useStore();
    const bu = ref<string[]>([]);
    const buList = computed(() =>
        state.user.buList.filter((item: any) => {
            if (item.parentId === null) return true;
        })
    );
    let initBu = '';
    const getDefaultBu = () => {
        const mbIndex = buList.value.findIndex((i: any) => {
            return i.nameEn === 'MBPC';
        });
        const initIndex = mbIndex === -1 ? 0 : mbIndex;
        initBu = buList.value[initIndex].nameEn;
        bu.value = [initBu];
    };
    getDefaultBu();
    const changeBu = () => {
        console.log('bu changed');
    };
    const getSelectBuIds = (buNames: string[]) => {
        return buList.value.filter((bu: {nameEn: string}) => buNames.includes(bu.nameEn))
          .map((bu: {id: number}) => bu.id);
    };

    // brand
    const brand = ref<UndefinedString>(undefined);
    const brandList = ref<any[]>([]);
    const getBrandList = (buIds: number[]) => {
        if (buIds.length) {
          findBrandByBu({ buIds: buIds }).then((res: any) => {
              const brands: string[] = []
              res.forEach((item: any) => {
                item.brandList.forEach((brand: any) => {
                  if (!brands.includes(brand.brandNameEn)) {
                    brands.push(brand.brandNameEn)
                  }
                })
              })
              brands.push('All')
              brandList.value = brands.map(brand => {
                return {
                  brandNameEn: brand
                }
              });
              brand.value = 'All';
          });
        } else {
          brandList.value = [{brandNameEn: 'All'}]
          brand.value = 'All';
        }
    };

    // make
    const make = ref<UndefinedString>(undefined);
    const makeList = ref([]);
    const getMakeList = (brandVal: string) => {
        if (!brandVal) {
            make.value = undefined;
            makeList.value = [];
            return;
        }
        let brand: any;
        if (brandVal === 'All') {
            brand = brandList.value
                .slice(0, brandList.value.length - 1)
                .map((item: any) => item.brandNameEn);
        } else {
            brand = [brandVal];
        }
        const params = {
            brand,
            make: [],
            modelName: [],
            nst: [],
            typeClass: [],
            powerTrain: [],
        };
        searchVehicle(params).then((res: any) => {
            const makes = res.make;
            makes.push('All');
            makeList.value = makes;
            make.value = 'All';
        });
    };
    watch(brand, (newVal: any) => {
        getMakeList(newVal);
    });

    // typeclass
    const typeClass = ref<UndefinedString>(undefined);
    const typeClassList = ref([]);
    const getTypeClassList = () => {
        getTypeClass().then((res: any) => {
            // const typeClasses = res
            // typeClasses.push('All')
            typeClassList.value = res;
        });
    };
    getTypeClassList();

    // regioin/ subregion
    const sortRegions = (regions: any) => {
        const sortArr: any[] = [[], [], [], [], []];
        const maps = {
            n: 0,
            e: 1,
            w: 2,
            s: 3,
        };
        regions.forEach((region: any) => {
            const firstLetter = (region
                .regionEn[0] as string).toLocaleLowerCase();
            if (firstLetter in maps) {
                sortArr[maps[firstLetter]].push(region);
            } else {
                sortArr[4].push(region);
            }
        });
        return sortArr.flat() as any;
    };
    const region = ref<UndefinedString>(undefined);
    const regionList = ref<any>([]);
    const subRegion = ref<UndefinedString>(undefined);
    const subRegionList = ref<any>([]);
    const getRegionList = async (buIds: number[]) => {
      let regions = []
      if (buIds.length) {
        regions = sortRegions(await getRegionListAPI(buIds));
      }

      regions.push({ regionEn: 'All' });
      regionList.value = regions;
      region.value = 'All';
      subRegion.value = 'All';
    };
    const getSelectRegionId = (regionName: string) => {
        const index = regionList.value.findIndex(
            (item: any) => item.regionEn === regionName
        );
        return regionList.value[index]?.id;
    };
    const getSubRegionList = async (buIds: number[], regionId: number) => {
      let subRegions = []
      if (buIds.length) {
        subRegions = sortRegions(await getRegionListAPI(buIds, regionId));
      }
      subRegions.push({ regionEn: 'All' });
      subRegionList.value = subRegions;
      subRegion.value = 'All';
    };
    const isVAN = ref(false);
    watch(
        //* bu 改变 获取 brand 和 region
        bu,
        (newBu: any) => {
            const buIds = getSelectBuIds(newBu);
            getBrandList(buIds);
            getRegionList(buIds);
            if (newBu.includes('VAN')) {
                isVAN.value = true;
            } else {
              isVAN.value = false;
            }
        },
        {
            immediate: true,
        }
    );
    watch(
        //* region 改变 获取subregion
        region,
        (newRegion: any) => {
            if (newRegion && newRegion !== 'All') {
                const buIds = getSelectBuIds(bu.value);
                const regionId = getSelectRegionId(newRegion);
                getSubRegionList(buIds, regionId);
            } else if (newRegion === 'All') {
                subRegion.value = 'All';
                subRegionList.value = [{ regionEn: 'All' }];
            } else {
                subRegion.value = undefined;
                subRegionList.value = [];
            }
        },
        {
            immediate: true,
        }
    );

    // province/city
    const province = ref<UndefinedString>(undefined);
    const provinceList = ref<any>([]);
    const city = ref<UndefinedString>(undefined);
    const cityList = ref<any>([]);
    let provinceCityMap: any;
    const getProvinceList = () => {
        getAreasDate().then((res: any) => {
            const provinces = res;
            provinces.push({ provinceEn: 'All' });
            provinceList.value = provinces;
            provinceCityMap = res.reduce((maps: any, pro: any) => {
                return { ...maps, [pro.provinceEn]: pro.cityList };
            }, {});
            provinceCityMap['All'] = [{ cityEn: 'All' }];
            province.value = 'All';
        });
    };
    getProvinceList();
    watch(province, (newProvince) => {
        if (newProvince && newProvince !== 'All') {
            cityList.value = [
                ...provinceCityMap[newProvince],
                { cityEn: 'All' },
            ];
            city.value = 'All';
        } else if (newProvince === 'All') {
            city.value = 'All';
            cityList.value = provinceCityMap[newProvince];
        } else {
            city.value = undefined;
            cityList.value = [];
        }
    });

    // year Q/M
    const year = ref<UndefinedString>(undefined);
    // const qm = ref<string[]>([]);
    const qm = ref<UndefinedString>(undefined);
    const openYear = ref(false);
    const handlerOpenYearChange = (status: any): void => {
        openYear.value = status;
    };
    const handlerPanelYearChange = (value: string): void => {
        const time = moment(value).format('YYYY');
        year.value = time as string;
        openYear.value = false;
    };
    watch(year, (newVal) => {
        if (!newVal) {
            // qm.value = [];
            qm.value = undefined;
        }
    });

    // restricted city
    const restrictedCity = ref<UndefinedString>('All');
    const restrictedCityType = ['Y', 'N', 'All'];

    // dealer name / dealer group
    const dealerName = ref('');
    const dealerGroup = ref('');

    const calculationRule = ref<UndefinedString>(undefined);
    const calculationRuleType = ref<any>([]);

    const paramsCheck = () => {
        if (bu.value.length === 0) {
            message.warning('BU must be selected!');
            return true;
        }
        if (year.value === undefined) {
            message.warning('Year must be selected!');
            return true;
        }
        if (calculationRule.value === undefined) {
            message.warning('Calculation rule must be selected!');
            return true;
        }
        return false;
    };

    return {
        spaceSize,
        viewTypes,
        selectedViewType,
        selectViewType,
        initBu,
        bu,
        buList,
        changeBu,
        brand,
        brandList,
        make,
        makeList,
        typeClass,
        typeClassList,
        region,
        regionList,
        subRegion,
        subRegionList,
        province,
        provinceList,
        city,
        cityList,
        year,
        openYear,
        qm,
        handlerOpenYearChange,
        handlerPanelYearChange,
        restrictedCity,
        restrictedCityType,
        dealerName,
        dealerGroup,
        isVAN,
        paramsCheck,
        calculationRule,
        calculationRuleType,
    };
};

export default useSearchCondition;
